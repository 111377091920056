// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OurTippyWrapperFullScreen {
    max-width: none;
    transition-duration: 0ms;
    position: fixed;
    top: 0;
    left: 0;
    border: none !important;
    border-radius: 0 !important;
    width: 100%;
    height: 100%;
}

.OurTippyContentContainer {
    position:relative;
}
.TippyCloseButton {
    position:absolute;
    top:0;
    right:0;
}


.tipTest {
    width:300px;
    position:fixed;
    top:0;
    left:0;
    text-align: center;
    height: 200px;
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./webApp/src/ui/components/core/OurTippy/OurTippy.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,wBAAwB;IACxB,eAAe;IACf,MAAM;IACN,OAAO;IACP,uBAAuB;IACvB,2BAA2B;IAC3B,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;IACjB,KAAK;IACL,OAAO;AACX;;;AAGA;IACI,WAAW;IACX,cAAc;IACd,KAAK;IACL,MAAM;IACN,kBAAkB;IAClB,aAAa;IACb,uBAAuB;AAC3B","sourcesContent":[".OurTippyWrapperFullScreen {\n    max-width: none;\n    transition-duration: 0ms;\n    position: fixed;\n    top: 0;\n    left: 0;\n    border: none !important;\n    border-radius: 0 !important;\n    width: 100%;\n    height: 100%;\n}\n\n.OurTippyContentContainer {\n    position:relative;\n}\n.TippyCloseButton {\n    position:absolute;\n    top:0;\n    right:0;\n}\n\n\n.tipTest {\n    width:300px;\n    position:fixed;\n    top:0;\n    left:0;\n    text-align: center;\n    height: 200px;\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
