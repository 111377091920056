import React, { useCallback, useEffect } from 'react';
// import ReactDOM from 'react-dom';
import Tippy, { TippyProps } from "@tippyjs/react";
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/themes/light-border.css';
import './OurTippy.css';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import mergeRefs from '../../utils/mergeRefs';
// import { VModal } from '../VModal';
import { Instance as TippyInstance, Props as TippyJsProps } from 'tippy.js';
//Lazy Tippy
//linked from git page, howto, https://gist.github.com/atomiks/520f4b0c7b537202a23a3059d4eec908
//would want to bury hooks inside the components, so they aren't invoked (if slow) until render
//otherwise, they are invoked (unless controlled via an onshow prop or something)
export const OurLazyTippy = React.forwardRef((props:TippyProps, ref:React.Ref<OurTippyRefType>) => {
    const [mounted, setMounted] = React.useState(false);
  
    const lazyPlugin = {
      fn: () => ({
        onMount: () => setMounted(true),
        onHidden: () => setMounted(false),
      }),
    };
  
    const computedProps = {...props};
  
    computedProps.plugins = [lazyPlugin, ...(props.plugins || [])];
  
    if (props.render) {
      computedProps.render = (...args) => ((mounted && props.render) ? props.render(...args) : '');
    } else {
      computedProps.content = mounted ? props.content : '';
    }
  
    return <OurTippy {...computedProps} ref={ref} />;
  });


//element has a _tippy instance on it - unclear how the proper way to get it works ...     
export type OurTippyRefType = Element & { 
    _tippy: TippyInstance,
    // { 
    //     hide:() => void,
    //     popper:  
    // }
}

type OurTippyProps = TippyProps & {
    fullScreenWhenSmall?:boolean            //default: false, when true, tippy will transform into a fullscreen widget with close button
    showCloseButton?:boolean                //default: when full screen, always true, so only relevent on non-fullscreened version
}
const OurTippy = React.forwardRef( (tippyProps:OurTippyProps, ref:React.Ref<OurTippyRefType>) =>
{
    const { content, fullScreenWhenSmall, showCloseButton, trigger, visible, ...restProps } = tippyProps;
    
    const internalTippyRef = React.useRef<OurTippyRefType>(null);
    const goFullScreen = useMediaQuery('(max-width:767px)');

    // const isWeeWee = useMediaQuery('(max-width:767px)') || true;
    // const isWeeWee = false;

    // const [showing,setShowing] = useState(false);
    const closeTippy = useCallback(() => {
        if(internalTippyRef.current?._tippy)
        {
            internalTippyRef.current._tippy.hide();
        }
    },[internalTippyRef]);

    let { extraTippyProps, extraTippyJsProps } = React.useMemo(() => {
        let extraTippyProps:Partial<TippyProps> = {};
        let extraTippyJsProps:Partial<Pick<TippyJsProps,'popperOptions'|'maxWidth'>> = {}
        let classNameList = restProps.className ? [restProps.className, 'OurTippyWrapper'] : ['OurTippyWrapper'];
        if(goFullScreen && fullScreenWhenSmall)
        {
            extraTippyJsProps = {
                popperOptions:{
                    ...restProps.popperOptions,
                    modifiers: [{
                        name: 'computeStyles',
                        enabled: false,
                    }]
                },
                maxWidth: 'none'
            }
            classNameList.push('OurTippyWrapperFullScreen');

        }
        else 
        {
            extraTippyJsProps = {
                popperOptions:{
                    ...restProps.popperOptions,
                    modifiers: [{
                        name: 'computeStyles',
                        enabled: true,
                        options: {
                            gpuAcceleration: true,
                            roundOffsets: true,
                            apdaptive: true,
                        }
                    }]
                }
            }
        }
        extraTippyProps.className = classNameList.join(' ');

        return { extraTippyProps, extraTippyJsProps };
    },[goFullScreen, restProps.className, restProps.popperOptions, fullScreenWhenSmall]);

    // const [reRenderForPopper,setReRenderForPopper] = useState(goFullScreen);
    useEffect(() => {
        // return;
        // console.log('reRenderForPopper[effect]: ' + goFullScreen);
            
        if(internalTippyRef.current?._tippy)
        {            
            internalTippyRef.current._tippy.setProps(extraTippyJsProps); 
        }
    },[goFullScreen,extraTippyJsProps]);

    const showCloseButtonForRealsies = fullScreenWhenSmall && goFullScreen || showCloseButton;
    const contentWithCloseJsx = <div className="OurTippyContentContainer">
        {content}
        {showCloseButtonForRealsies && <button className="TippyCloseButton btn btn-default" onClick={closeTippy}>
            <FontAwesomeIcon icon={faTimes} />
        </button>}
    </div>


    //remove trigger prop if running in controlled mode
    // and if trigger is present, use it, otherwise default to 'click'
    let moreProps = typeof(visible) !== 'undefined' 
        ? { visible } 
        : { trigger: typeof(trigger) === 'undefined' ? 'click' : trigger };
/*
    //isWeeWee mode non-functional currently
    if(isWeeWee)
    {
        return <Tippy
            ref={ref}
            interactive={true} 
            animation={false}           //if using non-headless version for headless mode (ie w/render), should be false! seems to have bad behavior otherwise
            appendTo={document.body}
            
            onShow={() => setShowing(true)}
            onHide={() => setShowing(false)}
            {...moreProps}
            {...restProps}
            // render={attrs => <VModal 
            //     // wrapperAttributes={attrs}
            //     body={<div {...attrs}>{content}</div>}
            //     onHide={() => {}}
            //     show={showing}
            // />}
            popperOptions={{
                
                modifiers: [{
                    name: 'computeStyles',
                    enabled: false,
                }]
            }}
            render={ (attrs,content, instance) => {
                if(attrs['data-placement'] === undefined)
                    return undefined; //no show
                console.log('---- Inside Das Render ----');
                console.log(attrs);
                console.log(content);
                console.log(instance);
                // if(instance?.popper) //doesn't work - popper re'ups it instantly it seems
                //     instance.popper.style.transform = '';
                return <div className="tipTest" {...attrs}>Content for tip here</div>
            }}
            // onClickOutside={(i,e) => alert('yo outside!')}

            // popperOptions={{
              
            // }}
        >
            <span>
            {restProps.children}
            {showing && <Portal><div className="OurTippyWrapperFullScreen">
                {contentWithCloseJsx}
            </div></Portal>}
            </span>
        </Tippy>
    }
    //LOOK FOR FULLSCREEN MODAL to switch to instead... 
    if(isWeeWee)
    {
        return <Tippy 
            ref={mergeRefs([internalTippyRef, ref])}
            // className="TippyShiftPopup" 
            theme="light-border"
            animation={false} 
            interactive={true} 
            maxWidth={"100vw"}
            // placement="right-start"
            {...moreProps}
            {...restProps}
            className="OurTippyWrapperFullScreen"   
            // appendTo={document.body}
            getReferenceClientRect={() => ({
                width: 0,
                height: 0,
                left: -10,
                right: 0,
                top: 0,
                bottom: 0,
            } as ClientRect)}

            // popperOptions={{
            //     modifiers:[{
            //       name: 'computeStyles',
            //       options: {
            //         gpuAcceleration: false,
            //       },
            //     }]
            //   }}
            placement="right-start"
            arrow={false}

            content={contentWithCloseJsx}
        />
    }


    if(isWeeWee)
    {
        return <Tippy
            ref={ref}
            // className="TippyShiftPopup" 
            interactive={true} 
            trigger="click"
            appendTo={document.body}
            // sticky={false}
            // getReferenceClientRect={() => ({
            //     width: 100,
            //     height: 100,
            //     left: 0,
            //     right: 200,
            //     top: 0,
            //     bottom: 200,
            // })}
            onShow={() => setShowing(true)}
            onHide={() => setShowing(false)}
            // onCreate={(instance) => {
                
            //     instance.popperInstance.reference = {
            //        clientWidth: 0,
            //         clientHeight: 0,
            //         getBoundingClientRect() {
            //             return {
            //             // ...
            //             };
            //         }
            //     }
            // }}
            // inlinePositioning={false}
            // ignoreAttributes={true}
            render={attrs => <></>}

            // popperOptions={{
              
            // }}
        >
            <span>
            {restProps.children}
            {showing && <Portal><div className="OurTippyWrapperFullScreen">
                {contentWithCloseJsx}
            </div></Portal>}
            </span>
        </Tippy>
    }    
*/
    /*
let extraTippyProps:TippyProps = {}
    if(goFullScreen)
    {
        if(!reRenderForPopper)
            setReRenderForPopper(true);
        console.log('goFullScreen');
        extraTippyProps = {
            popperOptions:{
                ...restProps.popperOptions,
                modifiers: [{
                    name: 'computeStyles',
                    enabled: false,
                }]
            }
        }
    }
    else {
        console.log('goFullScreen - NOT');
        if(reRenderForPopper)
            setReRenderForPopper(false);
        // console.log('normal render');
        extraTippyProps = {
            popperOptions:{
                ...restProps.popperOptions,
                modifiers: [{
                    name: 'computeStyles',
                    enabled: true,
                    options: {
                        gpuAcceleration: true,
                        roundOffsets: true,
                        apdaptive: true,
                    }
                }]
            }
        }
    }

    */

    // return <LazyTippy 
    return <Tippy 
        ref={mergeRefs([internalTippyRef, ref])}
        // className="TippyShiftPopup" 
        theme="light-border"
        animation={false} 
        interactive={true} 
        // trigger="click"
        // maxWidth={500}
        // placement="right-start"
        {...moreProps}
        {...restProps}
        {...extraTippyProps}
        {...extraTippyJsProps} 
        // appendTo={document.body}
        content={contentWithCloseJsx}
        // {...extraTippyProps}
        // getReferenceClientRect={() => ({
        //     width: 0,
        //     height: 0,
        //     left: 0,
        //     right: 0,
        //     top: 0,
        //     bottom: 0,
        // })}
        // placement="right-start"
        // arrow={false}
    />
});
export const OurTippyOnClickOutsideStopClickity:TippyProps['onClickOutside'] = (instance, event) => {
    event.stopPropagation();
}
export default OurTippy;


// const Portal:React.FC<{
//     className?:string,
//     el?:string
// }> = ({ children, className = 'root-portal', el = 'div' }) => {
//     const [container] = React.useState(() => {
//       // This will be executed only on the initial render
//       // https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
//       return document.createElement(el);
//     });
  
//     React.useEffect(() => {
//       container.classList.add(className)
//       document.body.appendChild(container)
//       return () => {
//         document.body.removeChild(container)
//       }
//     }, [className, container])
  
//     return ReactDOM.createPortal(children, container)
//   }
  